@import "../../custom.scss";
@import "../../layout";

.drop2 {
  background-color: #000000;
  display: flex;
  flex-direction: column;

  height: 100%;

  border-bottom: solid 1px #ffffff;

  // big
  @media (min-width: $xl) {
    padding-left: 6.25%;
    padding-right: 6.25%;
    padding-bottom: 64px;
    padding-top: 32px;
  }

  // small
  @media (max-width: $xl) {
    padding: 16px;
  }

  .drop2-title {
    font-family: refrigerator-deluxe, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }

  .drop2-colorways-dropdown {
    margin-top: 16px;
    border: 1px solid #ffffff !important;
    background-color: #000000 !important;
    width: 100% !important;

    font-family: refrigerator-deluxe, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;

    padding: 8px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-transform: uppercase;

    color: #ffffff;

    background: url("../../assets/icons/select-down-arrow.svg") no-repeat 95%
      50%;
  }

  .drop2-colorways-tabs {
    margin-top: 24px;
    border-width: 0px !important;

    // big
    @media (min-width: $md) {
      display: auto;
    }

    // small
    @media (max-width: $md) {
      display: none;
    }

    .nav-item {
      // big
      @media (min-width: $lg) {
        padding-right: 80px;
      }

      // small
      @media (max-width: $lg) {
        padding-right: 40px;
      }

      .nav-link {
        background-color: #000000 !important;
        border-width: 0px !important;
        border-bottom: solid 2px #ffffff00 !important;
        color: #5f5f5f;

        font-family: refrigerator-deluxe, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;

        padding: 0px !important;

        &:hover {
          color: #ffffff !important;
          border-bottom: solid 2px #ffffff !important;
        }
      }
      .active {
        color: #ffffff !important;
      }
    }
  }

  .drop2-mint-details-container {
    margin-top: 24px;
  }

  .tab-content {
    height: 100% !important;
    overflow-y: hidden;
  }
}
