@import '../../../custom';
@import '../../../layout';

.devonta-mint-modal {
	// big
	@media (min-width: $md) {
		max-width: 650px !important;
	}
	// small
	@media (max-width: $md) {
		min-width: auto;
	}

	h1 {
		font-family: interstate, sans-serif;
		display: inline-block;
		margin-right: 20px;
	}

	.devonta-mint-modal-body {
		text-align: left;

		.modal-clickable {
			text-decoration: underline;
			cursor: pointer;
		}

		.devonta-mint-modal-video-container {
			border-radius: 20px;
			// margin: 20px;
			margin-top: 20px;
			overflow: hidden;
			.devonta-mint-modal-video {
				width: 100%;

				// padding-right: 20px;
			}
		}

		.devonta-mint-modal-link {
			font-family: interstate, sans-serif;
			font-size: 18px;
			width: 100%;
			font-weight: 600;
		}

		.devonta-modal-select {
			border-width: 0px;
			font-family: interstate, sans-serif;
			font-weight: 500;
			font-size: 34.72px;
			line-height: 1.2;
			margin-right: 20px;
		}

		.devonta-mint-modal-actions {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 20px;

			button {
				padding: 10px 40px;
			}
		}

		.gender-selector {
			cursor: pointer;
			font-family: interstate, sans-serif;
			font-style: normal;
		}
		.gender-selector:first-child {
			margin-right: 8px;
		}
		.gender-selector:last-child {
			margin-left: 8px;
		}

		.gender-selected {
			font-weight: 800;
			text-decoration: underline;
		}

		.devonta-mint-modal-error-text {
			color: #880000;
			font-size: 14px;
			font-weight: bold;
			margin-top: 8px;
		}

		.devonta-mint-modal-sizes {
			margin-top: 8px;
		}

		.devonta-mint-modal-size-button {
			display: inline-block;
			cursor: pointer;
			padding: 12px;
			margin: 4px;
			margin-left: 0px;
			border-radius: 4px;
			border: 1px solid #dfdfdf;
			min-width: 56px;
			text-align: center;
		}

		.devonta-mint-modal-size-selected {
			border: 1px solid #000000;
		}
		.devonta-mint-modal-size-unselected {
			&:hover {
				border: 1px solid #000000;
			}
			&:active,
			&:focus {
				border: 1px solid #000000;
			}
		}
	}
}
