@import '../../App.scss';

.cart {
	width: 100%;
	background-color: #ffffff;
	padding-top: 80px;
	padding-bottom: 240px;

	p {
		margin: 0px;
	}

	h1 {
		font-family: refrigerator-deluxe, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 72px;
		line-height: 72px;
	}

	h2 {
		margin: 0px;

		font-family: refrigerator-deluxe, sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 40px;

		text-transform: uppercase;
	}

	h3 {
		margin: 0px;

		font-family: refrigerator-deluxe, sans-serif;
		font-size: 32px;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: 0em;

		text-transform: uppercase;
	}

	.container {
		max-width: 1200px;
	}

	.cart-subtotal-items {
		font-family: interstate;
		font-size: 20px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: center;
	}

	.cart-line-item-container {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		width: 100%;

		.cart-line-item {
			display: flex;
			flex-direction: row;

			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #d4d4d4;

			.cart-line-item-icon {
				height: 120px;
				width: 120px;
			}

			.cart-line-item-details {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;
				padding-left: 20px;
				padding-right: 20px;

				.cart-size-text {
					margin-top: 16px;
					margin-bottom: 0px;

					font-family: interstate;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0.15px;
					text-align: left;
				}

				.link-button {
					font-family: interstate;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0.15px;
					text-align: left;
				}
			}

			.form-select {
				// background: #ffffff;
				box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
				border-radius: 4px;
				border-width: 0px;

				font-family: interstate;
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 100%;

				padding: 16px;
			}

			.cart-line-item-price {
				font-family: interstate;
				font-size: 20px;
				font-weight: 700;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: left;
			}
		}
	}
}
