@import "../../custom.scss";
@import "../../layout";

.drop2-voucher {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Roboto Mono", monospace;
  font-weight: 700;

  .file-container {
    flex-direction: column;
    align-items: center;
    padding: 25px;
    max-width: 640px;
    width: 100%;

    // big
    @media (min-width: $lg) {
      border: 60px solid #e80000;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    // small
    @media (max-width: $lg) {
      border: 16px solid #e80000;
    }

    .error-text {
      color: #e80000;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    h1 {
      // big
      @media (min-width: $lg) {
        font-size: 82px;
      }

      // small
      @media (max-width: $lg) {
        font-size: 36px;
      }

      font-family: refrigerator-deluxe, sans-serif;
      color: #e80000;
      text-align: center;
      font-weight: bold;
    }

    .subtitle {
      color: #e80000;
      text-align: center;
      font-family: interstate, sans-serif;
      font-weight: bold;
    }

    p {
      color: #000000;
      // big
      @media (min-width: $lg) {
        font-size: 18px;
      }

      // small
      @media (max-width: $lg) {
        font-size: 16px;
      }
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    .copy {
      margin-top: 30px;

      p {
        margin-bottom: 25px;
      }
    }

    .colorways-container {
      margin-top: 30px;
      display: grid;
      gap: 25px;

      // big
      @media (min-width: $lg) {
        grid-template-columns: 220px 220px;
      }

      // small
      @media (max-width: $lg) {
        grid-template-columns: auto;
      }

      .colorways-item {
        display: flex;
        flex-direction: column;

        img {
          border: 1px solid #e80000;
        }

        .colorways-price {
          display: flex;
          flex-direction: row;
          align-self: center;
          align-items: center;
          margin-top: 12px;
        }
      }

      .clickable {
        cursor: pointer;
      }
    }
  }

  .success-container {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .success-image {
      width: 200px;
    }

    .mint-address {
      white-space: pre-wrap;
      display: block;
      text-overflow: ellipsis;
      color: #000000;
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 16px;
      word-break: break-all;
      word-wrap: break-word;
    }
  }

  .image-carousel-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px;

    .image-carousel {
      margin-bottom: 48px;

      // big
      @media (min-width: $lg) {
        width: 400px;
      }

      // small
      @media (max-width: $lg) {
        width: 100%;
      }
    }
  }
}
