@import '../../../custom.scss';
@import '../../../layout';

.devonta-mint-button-component {
	display: inline-block;
	.devonta-mint-error {
		width: 100% !important;
		margin-bottom: 16px !important;
		background-color: #ffffff !important;
		color: #d00000 !important;
		padding: 12px !important;

		font-family: interstate, sans-serif !important;
		font-style: normal !important;
		font-weight: 800 !important;
		font-size: 16px !important;
		line-height: 100% !important;
		text-align: center !important;

		margin-top: 0px !important;
	}

	.devonta-mint-transaction {
		width: 100%;
		margin-bottom: 16px;
		background-color: #ffffff;
		padding: 8px;
		p {
			margin-top: 16px;
			margin-bottom: 16px;

			color: #000000;
			text-decoration-color: #000000;

			font-family: interstate, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px !important;
			line-height: 100%;
			text-align: center;
		}
	}
}

.devonta-mint-button-full {
	display: block !important;
}

.devonta-mint-modal {
	.close {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 8px;

		.close-button {
			cursor: pointer;
		}
	}

	.modal-content {
		text-align: center;

		// big
		@media (min-width: $md) {
			padding: 2rem;
		}
		// small
		@media (max-width: $md) {
			padding: 0.5rem;
		}

		.wallet-btn {
			width: 49%;
			padding: 16px;
		}

		a {
			width: 49%;

			.paper-btn {
				width: 100%;
				padding: 16px;

				&:active {
					background-color: #ffffff;
				}

				&:focus {
					background-color: #ffffff;
				}
			}
		}

		.title {
			font-family: interstate, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;
			text-align: center;
			color: #23262f;
		}
		.subtitle {
			font-family: interstate, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #87898e;
		}
	}
}

.devonta-mint-extra-button {
	padding: 16px !important;
	flex-grow: 1;
	margin-left: 8px;
	margin-right: 8px;
	max-width: 45%;
	width: 100%;
}
