@import '../../custom.scss';
@import '../../layout';
.crowther-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: #000000;

	.crowther {
		display: flex;
		flex-direction: column;

		height: 100%;

		// small
		@media (max-width: $xl) {
			max-width: 1200px;
		}

		border-bottom: solid 1px #ffffff;

		// big
		@media (min-width: $xl) {
			padding-left: 6.25%;
			padding-right: 6.25%;
			padding-bottom: 64px;
			padding-top: 32px;
		}

		// small
		@media (max-width: $xl) {
			padding: 16px;
		}

		.crowther-title {
			font-family: refrigerator-deluxe, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
			color: #ffffff;
		}

		.crowther-mint-details-container {
			margin-top: 24px;
		}
	}
}
