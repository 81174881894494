@import 'layout';
@import 'custom';

html,
body,
#root {
	height: 100vh;
	font-family: interstate, sans-serif;
}

.container-fluid {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.parent {
	display: flex;
	flex-direction: column;
	height: 100vh;
	flex: 1 0 auto;
	background-color: #000;

	.content {
		flex: 1 0 auto;
	}
}

.overlay {
	background-color: #000;
	border-radius: inherit;
	overflow: hidden;
	opacity: 40%;
	position: absolute;
	width: 100%;
}

.hidden {
	visibility: hidden;
}

.clickable {
	cursor: pointer;
}

.link-button {
	background-color: #00000000;
	border: none;
	text-decoration: underline;
	padding: 0px;
	font-family: refrigerator-deluxe, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
}

.modal-close {
	align-self: flex-end;
}

.keep-whitespace {
	white-space: pre-wrap;
}

.size-dropdown {
	margin-right: 20px;
	background-color: #ffffff;
	box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
	border-radius: 4px;

	width: auto !important;

	font-family: interstate;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 100%;

	padding: 16px 48px 16px 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	text-align: left;

	color: #000000;

	// background: url('./assets/icons/select-down-arrow.svg') no-repeat 95% 50%;
}

// Global styles

// .container {
// 	max-width: 1440px;
// 	margin-left: auto;
// 	margin-right: auto;
// desktop - large
// @media (min-width: $xl) {
// 	max-width: 1440px;
// 	margin-left: auto;
// 	margin-right: auto;
// }

// // desktop - medium
// @media (max-width: $xl) {
// 	width: 55%;
// }

// // phone - large
// @media (max-width: $lg) {
// 	width: 55%;
// }

// // phone - small
// @media (max-width: $md) {
// 	width: 100%;
// }
// }
