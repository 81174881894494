@import "../../custom.scss";
@import "../../layout";

.drop2-overview {
  background-color: #000000;
  display: flex;
  flex-direction: column;

  .drop2-overview-image {
    // big
    @media (min-width: $lg) {
      width: 50%;
      object-fit: contain;
    }

    // small
    @media (max-width: $lg) {
      width: 100%;
    }
  }

  .drop2-overview-colorway {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-wrap;

    // big
    @media (min-width: $lg) {
      width: 50%;
      padding: 228px 114px 114px 114px;
    }

    // small
    @media (max-width: $lg) {
      width: 100%;
      padding: 32px;
    }

    h1 {
      font-family: refrigerator-deluxe, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 100%;

      text-transform: uppercase;

      color: #000000;
    }

    p {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 200;

      line-height: 100%;

      color: #000000;

      margin-top: 32px;

      // big
      @media (min-width: $lg) {
        font-size: 72px;
      }

      // small
      @media (max-width: $lg) {
        font-size: 50px;
      }
    }

    .drop2-overview-mint-button-container {
      margin-top: 62px;
      margin-bottom: 32px;

      display: flex;
      flex-wrap: wrap;
      .drop2-overview-mint-button-parent {
        margin-right: 56px;
        margin-bottom: 32px;
        .drop2-overview-mint-button {
          width: 240px;
          height: 56px;
        }
      }

      .drop2-overview-mint-button-outline {
        width: 240px;
        height: 56px;
        background-color: #ffffff;
        border: 1px solid #000000 !important;
        color: #000000;
        margin-bottom: 32px;
      }
    }

    .drop2-overview-colorway-details {
      display: flex;
      flex-direction: row;

      .drop2-overview-colorway-details-item {
        display: flex;
        flex-direction: column;
        flex: 1;

        h2 {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;

          color: #000000;

          margin-top: 16px;
        }

        p {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;

          color: #000000;
        }
      }
    }
  }

  .drop2-overview-statecraft {
    padding: 5%;
    background-color: #000000;

    // big
    @media (min-width: $lg) {
      width: 50%;
    }

    // small
    @media (max-width: $lg) {
      width: 100%;
    }

    h1 {
      font-family: refrigerator-deluxe, sans-serif;
      font-style: normal;
      font-weight: 700;

      line-height: 100%;

      text-transform: uppercase;

      color: #ffffff;

      margin-bottom: 72px;

      // big
      @media (min-width: $lg) {
        font-size: 72px;
      }

      // small
      @media (max-width: $lg) {
        font-size: 58px;
      }
    }

    p {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      text-align: justify;
      letter-spacing: 0.15px;

      color: #ffffff;

      margin-bottom: 32px;
    }
  }

  .drop2-overview-ltr {
    // big
    @media (min-width: $lg) {
      flex-direction: row;
    }

    // small
    @media (max-width: $lg) {
      flex-direction: column-reverse;
    }
  }

  .drop2-overview-rtl {
    // big
    @media (min-width: $lg) {
      flex-direction: row-reverse;
    }

    // small
    @media (max-width: $lg) {
      flex-direction: column-reverse;
    }
  }

  .drop2-overview-ticker {
    background-color: #ffffff;
    width: 100%;
    border-bottom: solid 1px #000000;
    border-top: solid 1px #000000;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding-left: 32px;
    padding-right: 32px;

    // small
    @media (max-width: $lg) {
      display: none;
    }

    p {
      font-family: refrigerator-deluxe, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 42px;

      padding-top: 16px;
      padding-bottom: 16px;

      text-transform: uppercase;

      color: #000000;

      // medium
      @media (max-width: $xl) {
        font-size: 30px;
      }
    }
  }

  .drop2-overview-howitworks {
    display: flex;
    flex-direction: column;
    background-color: #000000;

    // big
    @media (min-width: $lg) {
      padding: 114px;
    }

    // small
    @media (max-width: $lg) {
      padding: 32px;
    }

    border-top: solid 1px #ffffff;

    h1 {
      font-family: refrigerator-deluxe, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 72px;

      letter-spacing: 0.15px;
      text-transform: uppercase;

      color: #ffffff;

      // big
      @media (min-width: $lg) {
        margin-bottom: 80px;
      }

      // small
      @media (max-width: $lg) {
        margin-bottom: 64px;
      }
    }

    .drop2-overview-howitworks-steps {
      display: flex;

      // big
      @media (min-width: $lg) {
        flex-direction: row;
        justify-content: space-between;
      }

      // small
      @media (max-width: $lg) {
        flex-direction: column;
      }

      .drop2-overview-howitworks-step {
        border: solid 1px #ffffff;

        // big
        @media (min-width: $lg) {
          padding: 48px;
          max-width: 30%;
        }

        // small
        @media (max-width: $lg) {
          padding: 32px;
          width: 100%;
          margin-bottom: 16px;
        }

        h2 {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;

          letter-spacing: 0.15px;

          color: #ffffff;
        }
        h3 {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;

          letter-spacing: 0.15px;

          color: #ffffff;

          margin-top: 42px;
          margin-bottom: 16px;
        }
        p {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          letter-spacing: 0.15px;

          color: #ffffff;

          margin-top: 16px;
        }

        a {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          letter-spacing: 0.15px;

          color: #ffffff;
        }

        .indented {
          padding-left: 32px;
        }
      }
    }
  }

  .drop2-overview-drop0 {
    background-color: #000000;

    // big
    @media (min-width: $lg) {
      padding: 114px;
    }

    // small
    @media (max-width: $lg) {
      padding: 32px;
    }

    .drop2-overview-drop0-content {
      display: flex;
      flex-direction: column;
      position: relative;

      border: solid 1px #ffffff;

      // big
      @media (min-width: $lg) {
        padding: 114px;
      }

      // small
      @media (max-width: $lg) {
        padding: 32px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        border: solid 1px #ffffff;

        width: 100%;
        height: 100%;
        pointer-events: none;

        // big
        @media (min-width: $lg) {
          top: -16px;
          left: 16px;
        }

        // small
        @media (max-width: $lg) {
          top: -8px;
          left: 8px;
        }
      }

      p {
        font-family: interstate, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;

        letter-spacing: 0.15px;

        color: #ffffff;

        margin-bottom: 32px;

        > a {
          font-family: interstate, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;

          letter-spacing: 0.15px;

          color: #ffffff;
        }
      }

      .drop2-overview-perks {
        display: flex;

        // big
        @media (min-width: $lg) {
          flex-direction: row;
          justify-content: space-between;
          margin-top: 80px;
        }

        // small
        @media (max-width: $lg) {
          flex-direction: column;
          margin-top: 32px;
        }

        .drop2-overview-perk {
          display: flex;
          flex-direction: column;

          // big
          @media (min-width: $lg) {
            max-width: 30%;
          }

          // small
          @media (max-width: $lg) {
            width: 100%;
          }

          h2 {
            font-family: interstate, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;

            letter-spacing: 0.15px;

            color: #ffffff;

            margin-bottom: 24px;

            // small
            @media (max-width: $lg) {
              margin-top: 16px;
            }
          }
          p {
            font-family: interstate, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;

            letter-spacing: 0.15px;

            color: #ffffff;

            // big
            @media (min-width: $lg) {
              margin-bottom: 16px;
            }

            // small
            @media (max-width: $lg) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
