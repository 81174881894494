@import '../../App.scss';

.attributes {
	color: #ffffff;
	display: flex;
	min-height: 0;
	overflow-y: auto;
	flex-direction: column;

	margin-top: 24px;

	.attributes-header-container {
		padding-bottom: 16px;

		border-bottom: 1px solid #ffffff;

		.attributes-header {
			font-family: interstate, sans-serif;
			font-size: 16px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0em;
		}
	}

	.attributes-line-container {
		overflow-y: scroll;

		.attributes-line {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 8px;

			p {
				font-family: interstate, sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.15px;
			}
		}
	}
}
