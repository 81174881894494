@import '../../../custom';
@import '../../../layout';

.donation-modal {
	// small
	@media (max-width: $md) {
		min-width: auto;
	}

	.crowther-mint-button {
		padding: 16px;

		background-color: #cf263b !important;
		color: #ffffff !important;
	}

	.crowther-slider-container {
		padding: 8px;
		width: 100%;

		.crowther-slider-description {
			font-size: 16px;
		}

		.crowther-slider-value {
			font-family: interstate, sans-serif !important;
			font-style: normal !important;
			font-weight: 800 !important;
			font-size: 24px !important;
		}
	}
}
