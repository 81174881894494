@import '../../App.scss';

.perks {
	width: 100%;

	.perks-tabs {
		display: flex;
		flex-direction: row;

		border-bottom: 1px solid #ffffff;

		.perks-tab {
			cursor: pointer;
			margin-right: 16px;
			margin-bottom: 16px;

			p {
				font-family: interstate;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 100%;

				text-align: center;
				text-transform: uppercase;

				color: #ffffff;
			}
		}
		.perks-tab-selected {
			p {
				font-weight: 700;
			}
		}
	}

	.dropdown-toggle {
		width: 100%;

		font-family: interstate;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 100%;

		text-align: center;
		text-transform: uppercase;

		color: #ffffff !important;

		text-align: start;
	}

	.perks-content {
		color: #ffffff;
		margin-top: 16px;

		.perks-bullet-list {
			display: flex;
			flex-direction: column;

			.perks-bullet-item {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;

				letter-spacing: 0.15px;
			}
		}
	}
}
