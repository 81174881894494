@import '../../App.scss';

.bc-vertical-item {
	display: flex;
	flex-direction: column;

	flex-grow: 1;

	position: relative;

	cursor: pointer;

	.bc-vertical-img {
		width: 100%;
		height: 80vh;

		object-fit: cover;

		overflow: hidden;

		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}

	.color-in {
		transition: filter 0.35s ease-in-out;
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%);
	}

	.color-out {
		transition: filter 0.35s ease-in-out;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}

	.bc-vertical-img:hover {
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%);
	}

	// https://stackoverflow.com/questions/7273927/image-greyscale-with-css-re-color-on-mouse-over

	.bc-vertical-img-zoom-in {
		// animation: scaleIn 0.35s ease-in-out forwards;
		// -webkit-animation: scaleIn 0.35s ease-in-out forwards;

		// @keyframes scaleIn {
		// 	0% {
		// 		transform: scale(1);
		// 	}
		// 	100% {
		// 		transform: scale(1.1);
		// 	}
		// }
	}

	.bc-vertical-img-zoom-out {
		// animation: scaleOut 0.35s ease-in-out forwards;
		// -webkit-animation: scaleOut 0.35s ease-in-out forwards;

		// @keyframes scaleOut {
		// 	0% {
		// 		transform: scale(1.1);
		// 	}
		// 	100% {
		// 		transform: scale(1);
		// 	}
		// }
	}

	.bc-vertical-collapse {
		position: absolute;
		width: 100%;

		bottom: 0;

		.bc-vertical-details {
			background: rgb(0, 0, 0);
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 1) 30%,
				rgba(0, 0, 0, 0) 100%
			);
			padding: 20px 20px;
		}

		.ReactCollapse--collapse {
			transition: height 250ms;

			display: flex;
			flex-direction: column;
		}

		h2 {
			color: #ffffff;

			font-family: refrigerator-deluxe, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			line-height: 40px;
			letter-spacing: 0em;
			text-align: center;

			text-transform: uppercase;

			font-size: 52px;
			line-height: 100%;
		}

		.collab-description {
			color: #000000;

			font-family: interstate, sans-serif;
			font-style: normal;
			font-weight: 400;

			margin: 0px;

			padding-bottom: 40px;
		}
	}
}
