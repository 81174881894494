@import '../../layout';

.header-overlay {
	background-color: #00000000 !important;
	position: absolute;
	width: 100%;
	z-index: 2 !important;
}

.header {
	background-color: #000000;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	z-index: 1000;
	// big
	@media (min-width: $lg) {
		align-items: center;
		padding: 30px 6.25%;
	}

	// small
	@media (max-width: $lg) {
		align-items: center;
		padding: 10px 10px;
	}

	.dropdown-toggle::before {
		display: none !important;
	}

	.dropdown-menu {
		padding-left: 10px;
		padding-right: 10px;
	}

	.logo {
		// big
		@media (min-width: $lg) {
			width: 180px;
		}

		// small
		@media (max-width: $lg) {
			width: 120px;
		}
	}
	.section {
		display: flex;
		flex-direction: row;
		&.left {
			justify-content: flex-start;
		}
		&.center {
			justify-content: center;
		}
		&.right {
			// big
			@media (min-width: $sm) {
				justify-content: flex-end;
			}

			// small
			@media (max-width: $sm) {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				text-align: end;
			}
		}

		.dropdown-toggle::after {
			display: none !important;
		}
	}

	.header-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		// margin-left: 16px;
		background-color: #000000;
		// height: 42px;
		// width: 42px;
		color: #ffffff;

		font-family: refrigerator-deluxe, sans-serif;
		font-style: normal;
		font-weight: 700;

		border: none;

		text-align: center;
		text-decoration: none;

		p {
			margin: 0px;
		}
	}

	.header-button-border {
		border: solid 1px #ffffff;
		border-radius: 21px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.header-cart-link {
		color: #ffffff;

		font-family: interstate, sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 32px;

		text-transform: uppercase;
		text-decoration: none;

		align-self: center;
	}

	.header-link {
		color: #ffffff;

		font-family: refrigerator-deluxe, sans-serif;
		text-decoration: none;
		font-weight: 500;
		font-size: 22px;
	}

	.header-link-underline {
		text-decoration: underline;
	}
}
