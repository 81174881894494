@import '../../custom.scss';
@import '../../layout';

.devonta-container {
	background-color: #000000;
	display: flex;
	flex-direction: column;

	.devonta-background-video {
		position: fixed;
		height: 60vh;
		width: 100vw;
		z-index: 1;

		margin-top: -10vh;

		video {
			width: 100vw;
		}
	}

	.devonta-top {
		z-index: 2;

		// big
		@media (min-width: $lg) {
			margin-top: 78vh;
		}

		// small
		@media (max-width: $lg) {
			margin-top: 72vh;
		}

		.devonta-overview-header {
			background-color: #00000090;

			padding: 2% 8% 2% 8%;
		}
	}

	h1 {
		background-color: #000000;

		font-family: refrigerator-deluxe, sans-serif;
		font-style: normal;
		font-weight: 700;

		line-height: 100%;

		text-transform: uppercase;

		color: #ffffff;

		width: 100%;

		// big
		@media (min-width: $lg) {
			font-size: 72px;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		// small
		@media (max-width: $lg) {
			font-size: 58px;

			display: flex;
			flex-direction: column;
		}

		button {
			width: 240px;
			padding: 16px;

			color: #ffffff !important;

			// big
			@media (min-width: $lg) {
				margin-right: 98px;
			}

			// small
			@media (max-width: $lg) {
				margin-top: 24px;
			}
		}
	}

	.devonta-overview-singles {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: #000000;

		padding-bottom: 20px;

		img {
			width: 33%;
			cursor: pointer;
		}
	}

	.devonta-overview {
		background-color: #000000;
		display: flex;
		flex-direction: column;

		.devonta-overview-header-assets {
			// big
			@media (min-width: $lg) {
				width: 50%;
			}

			// small
			@media (max-width: $lg) {
				width: 100%;
			}
			.devonta-overview-video {
				height: 600px;
				object-fit: cover;
			}
		}

		.devonta-overview-image {
			aspect-ratio: 1 / 1;
			// big
			@media (min-width: $lg) {
				width: 50%;
				object-fit: contain;
			}

			// small
			@media (max-width: $lg) {
				width: 100%;
			}

			.carousel-inner {
				height: 100%;

				.carousel-item {
					height: 100%;

					img {
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}

		.devonta-overview-colorway {
			background-color: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			white-space: pre-wrap;

			// big
			@media (min-width: $lg) {
				width: 50%;
				padding: 228px 114px 114px 114px;
			}

			// small
			@media (max-width: $lg) {
				width: 100%;
				padding: 48px 48px 96px 48px;
			}

			h1 {
				font-family: refrigerator-deluxe, sans-serif;
				font-style: normal;
				font-weight: 700;

				line-height: 100%;

				text-transform: uppercase;

				color: #000000;
				background-color: #ffffff;

				padding: 0;

				// big
				@media (min-width: $lg) {
					font-size: 72px;
				}

				// small
				@media (max-width: $lg) {
					font-size: 56px;
				}
			}

			p {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 200;

				line-height: 100%;

				color: #000000;

				margin-top: 32px;

				// big
				@media (min-width: $lg) {
					font-size: 72px;
				}

				// small
				@media (max-width: $lg) {
					font-size: 50px;
				}
			}

			.devonta-overview-mint-button-container {
				margin-top: 62px;
				margin-bottom: 32px;

				display: flex;
				flex-wrap: wrap;
				.devonta-overview-mint-button-parent {
					margin-right: 56px;
					margin-bottom: 32px;
					.devonta-overview-mint-button {
						width: 240px;
						height: 56px;
					}
				}

				.devonta-overview-mint-button-outline {
					width: 240px;
					height: 56px;
					background-color: #ffffff;
					border: 1px solid #000000 !important;
					color: #000000;
					margin-bottom: 32px;
				}
			}

			.devonta-overview-colorway-details {
				display: flex;
				flex-direction: row;

				.devonta-overview-colorway-details-item {
					display: flex;
					flex-direction: column;
					flex: 1;

					h2 {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 100%;

						color: #000000;

						margin-top: 16px;
					}

					p {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 100%;

						color: #000000;
					}
				}
			}
		}

		.devonta-overview-statecraft {
			padding: 3% 7% 7% 7%;
			background-color: #000000;
			margin-top: 20px;

			// big
			@media (min-width: $lg) {
				width: 50%;
			}

			// small
			@media (max-width: $lg) {
				width: 100%;
			}

			.devonta-overview-h1 {
				margin-bottom: 140px;
			}

			h2 {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 32px;
				line-height: 32px;

				letter-spacing: 0.15px;

				color: #ffffff;
			}

			h3 {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 18px;

				letter-spacing: 0.15px;

				color: #ffffff;

				margin-top: 30px;
			}

			p {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;

				letter-spacing: 0.15px;

				color: #ffffff;

				margin-top: 10px;
			}

			.devonta-overview-perks-table-container {
				display: inline-block;
				border: solid 1px #ffffff;
				padding: 20px;
				margin-top: 30px;
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					border: solid 1px #ffffff;

					width: 100%;
					height: 100%;
					pointer-events: none;

					// big
					@media (min-width: $lg) {
						top: -8px;
						left: 8px;
					}

					// small
					@media (max-width: $lg) {
						top: -4px;
						left: 4px;
					}
				}

				.devonta-overview-perks-table {
					color: #ffffff;

					th {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;

						letter-spacing: 0.15px;

						color: #ffffff;
					}

					td {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 20px;

						letter-spacing: 0.15px;

						color: #ffffff;

						padding-top: 15px;
					}

					td:not(:first-child):not(:last-child) {
						padding-left: 20px;
						padding-right: 20px;
					}
				}
			}

			// .devonta-overview-perks-table-container:after {
			// 	border: solid 1px #ffffff;
			// 	padding: 8px;
			// }
		}
	}

	.devonta-overview-ltr {
		// big
		@media (min-width: $lg) {
			flex-direction: row;
		}

		// small
		@media (max-width: $lg) {
			flex-direction: column-reverse;
		}
	}

	.devonta-overview-rtl {
		// big
		@media (min-width: $lg) {
			flex-direction: row-reverse;
		}

		// small
		@media (max-width: $lg) {
			flex-direction: column-reverse;
		}
	}

	.devonta-overview-ticker {
		background-color: #ffffff;
		width: 100%;
		border-bottom: solid 1px #000000;
		border-top: solid 1px #000000;

		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		padding-left: 32px;
		padding-right: 32px;

		// small
		@media (max-width: $lg) {
			display: none;
		}

		p {
			font-family: refrigerator-deluxe, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 42px;

			padding-top: 16px;
			padding-bottom: 16px;

			text-transform: uppercase;

			color: #000000;

			// medium
			@media (max-width: $xl) {
				font-size: 30px;
			}
		}
	}

	.devonta-overview-howitworks {
		display: flex;
		flex-direction: column;
		background-color: #000000;

		// big
		@media (min-width: $lg) {
			padding: 200px 114px;
		}

		// small
		@media (max-width: $lg) {
			padding: 32px;
		}

		border-top: solid 1px #ffffff;

		.devonta-overview-howitworks-header {
			display: block;
			// text-align: center;

			font-family: refrigerator-deluxe, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 72px;

			letter-spacing: 0.15px;
			text-transform: uppercase;

			color: #ffffff;

			// big
			@media (min-width: $lg) {
				margin-bottom: 100px;
			}

			// small
			@media (max-width: $lg) {
				margin-bottom: 64px;
			}
		}

		.devonta-overview-howitworks-steps {
			display: flex;

			// big
			@media (min-width: $lg) {
				flex-direction: row;
				justify-content: space-between;
			}

			// small
			@media (max-width: $lg) {
				flex-direction: column;
			}

			.devonta-overview-howitworks-step {
				display: flex;
				flex-direction: column;
				h1 {
					font-size: 42px;
					line-height: 42px;
				}

				// big
				@media (min-width: $lg) {
					max-width: 30%;
				}

				// small
				@media (max-width: $lg) {
					width: 100%;
					margin-bottom: 16px;
					margin-top: 60px;
				}

				.devonta-overview-howitworks-step-content {
					border: solid 1px #ffffff;
					display: flex;
					flex-direction: column;
					position: relative;

					flex-grow: 1;

					// big
					@media (min-width: $lg) {
						padding: 180px 48px 48px 48px;
					}

					// small
					@media (max-width: $lg) {
						padding: 180px 32px 32px 32px;
					}

					.devonta-overview-howitworks-icon {
						position: absolute;
						padding: 20px;
						align-self: center;
						translate: 0% -50%;
						top: 0;

						background-color: #000000;

						// small
						@media (max-width: $lg) {
							max-width: 160px;
						}

						&.icon-1 {
							width: 75%;
						}
						&.icon-2 {
							width: 50%;
						}
						&.icon-3 {
							width: 55%;
						}
					}

					h2 {
						width: 100%;
						text-align: center;
						font-family: refrigerator-deluxe, sans-serif;
						font-style: normal;
						font-weight: 700;
						line-height: 100%;
						font-size: 32px;
						letter-spacing: 0.15px;
						min-height: 64px;

						color: #ffffff;

						margin-bottom: 24px;
					}
					p {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;

						letter-spacing: 0.15px;

						color: #ffffff;

						margin-top: 16px;

						padding-left: 0.8em;
						text-indent: -0.8em;
					}

					a {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;

						letter-spacing: 0.15px;

						color: #ffffff;
					}

					.indented {
						padding-left: 32px;
					}
				}
			}
		}
	}

	.devonta-overview-drop0 {
		background-color: #000000;

		// big
		@media (min-width: $lg) {
			padding: 114px;
		}

		// small
		@media (max-width: $lg) {
			padding: 32px;
		}

		.devonta-overview-drop0-content {
			display: flex;
			flex-direction: column;
			position: relative;

			border: solid 1px #ffffff;

			// big
			@media (min-width: $lg) {
				padding: 114px;
			}

			// small
			@media (max-width: $lg) {
				padding: 32px;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				border: solid 1px #ffffff;

				width: 100%;
				height: 100%;
				pointer-events: none;

				// big
				@media (min-width: $lg) {
					top: -16px;
					left: 16px;
				}

				// small
				@media (max-width: $lg) {
					top: -8px;
					left: 8px;
				}
			}

			p {
				font-family: interstate, sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 32px;

				letter-spacing: 0.15px;

				color: #ffffff;

				margin-bottom: 32px;

				> a {
					font-family: interstate, sans-serif;
					font-style: normal;
					font-weight: 400;
					font-size: 24px;
					line-height: 32px;

					letter-spacing: 0.15px;

					color: #ffffff;
				}
			}

			.devonta-overview-perks {
				display: flex;

				// big
				@media (min-width: $lg) {
					flex-direction: row;
					justify-content: space-between;
					margin-top: 80px;
				}

				// small
				@media (max-width: $lg) {
					flex-direction: column;
					margin-top: 32px;
				}

				.devonta-overview-perk {
					display: flex;
					flex-direction: column;

					// big
					@media (min-width: $lg) {
						max-width: 30%;
					}

					// small
					@media (max-width: $lg) {
						width: 100%;
					}

					h2 {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 32px;

						letter-spacing: 0.15px;

						color: #ffffff;

						margin-bottom: 24px;

						// small
						@media (max-width: $lg) {
							margin-top: 16px;
						}
					}
					p {
						font-family: interstate, sans-serif;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 32px;

						letter-spacing: 0.15px;

						color: #ffffff;

						// big
						@media (min-width: $lg) {
							margin-bottom: 16px;
						}

						// small
						@media (max-width: $lg) {
							margin-bottom: 8px;
						}
					}
				}
			}
		}
	}
}
