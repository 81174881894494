@import '../../App.scss';

.link-connect-button {
	background-color: #00000000;
	border: none;
	text-decoration: underline;
	padding: 0px;

	font-family: interstate;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.15px;
}
