$primary: #4e44ce;

$font-primary: #000000;
$font-error: #e7c1c8;

$theme-colors: (
	'primary': $primary,
);

.btn {
	background-color: #4e44ce;

	padding-top: 19px;
	padding-bottom: 19px;

	font-family: refrigerator-deluxe, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	letter-spacing: 1px;

	text-align: center;

	color: #ffffff;

	border-radius: 0px !important;
	border-width: 0px !important;

	&.btn-outline-secondary {
		border: 1px solid #23262f !important;

		&:hover {
			background-color: #23262f;
			color: white;
		}
	}

	// &:disabled {
	//   background-color: #362f90;
	// }
}

.breadcrumb-item {
	color: #ffffff !important;

	font-family: refrigerator-deluxe, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	letter-spacing: 1px;

	text-transform: uppercase;

	a {
		color: #ffffff !important;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600;700&display=swap');
@import '~bootstrap/scss/bootstrap';
