@import "../../custom.scss";
@import "../../layout";

.drop2-mint {
  width: 100%;
  display: flex;

  justify-content: space-between;

  // big
  @media (min-width: $xl) {
    flex-direction: row;
    height: 688px;
  }

  // small
  @media (max-width: $xl) {
    flex-direction: column;
  }

  .drop2-mint-left {
    display: flex;

    // big
    @media (min-width: $xl) {
      flex-direction: row;
      width: 48%;
    }

    // medium
    @media (max-width: $xl) {
      flex-direction: row;
      width: 100%;
    }

    // small
    @media (max-width: $md) {
      flex-direction: column;
      width: 100%;
    }

    .drop2-mint-nft {
      display: flex;
      flex-direction: column;

      // big
      @media (min-width: $xl) {
        width: 55%;
        height: 688px;
      }

      // medium
      @media (max-width: $xl) {
        width: 55%;
      }

      // small
      @media (max-width: $md) {
        width: 100%;
      }

      video {
        width: 100%;
        margin-bottom: 16px;
      }

      .drop2-mint-nft-specs {
        background-color: #ffffff;
        flex-grow: 1;

        .drop2-perks-tab-container {
          border-bottom: 1px solid #000000;
          display: flex;
          flex-direction: row;

          .drop2-perks-tab {
            font-family: interstate, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            margin: 18px;

            display: flex;
            justify-content: center;
            text-align: center;
            flex: 1;
            cursor: pointer;

            background-color: #ffffff;
            color: #000000;

            p {
              border-bottom: solid 1px #00000000;

              &:hover {
                border-bottom: solid 1px #000000;
              }
            }
          }
          .drop2-perks-tab-selected {
            font-weight: 700;
          }
          .drop2-perks-tab-unselected {
            font-weight: 400;
          }
        }

        .drops2-perks-content {
          padding: 24px;

          .drop2-perks-bullet-list {
            display: flex;
            flex-direction: column;

            .drop2-perks-bullet-item {
              font-family: interstate, sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .drop2-mint-details {
      display: flex;
      flex-direction: column;

      // big
      @media (min-width: $xl) {
        width: 45%;
        height: 688px;
        margin-left: 16px;
        margin-right: 16px;
      }

      // medium
      @media (max-width: $xl) {
        margin-left: 16px;
        width: 45%;
        height: 928px;
      }

      // small
      @media (max-width: $md) {
        width: 100%;
        margin-top: 16px;
        margin-left: 0px;
      }

      .drop2-mint-details-attributes {
        background-color: #ffffff;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        .drop2-mint-details-attributes-line {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px;

          color: #000000;
          border-bottom: 1px solid #000000;

          .drop2-mint-details-attributes-text {
            display: flex;
            font-family: interstate, sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
          }

          .drop2-mint-details-attributes-header {
            display: flex;
            font-family: interstate, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }

  .drop2-mint-error {
    width: 100%;
    margin-bottom: 16px;
    background-color: #ffffff;
    color: #d00000;
    padding: 12px;

    font-family: interstate, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
  }

  .drop2-mint-description {
    background-color: #ffffff;
    margin-bottom: 16px;
    padding: 24px;

    font-family: interstate, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #333333;
  }

  .drop2-mint-model {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ffffff;

    // big
    @media (min-width: $xl) {
    }

    // medium
    @media (max-width: $xl) {
      margin-top: 16px;
      height: 800px;
    }

    // small
    @media (max-width: $md) {
      height: 350px;
      margin-top: 16px;
    }

    iframe {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .sketchfab-embed-wrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  .drop2-mint-details-button {
    width: 100%;
    margin-bottom: 16px;
  }
}
