@import '../../../custom.scss';
@import '../../../layout';

.crowther-mint-button-component {
	display: inline-block;

	// small
	@media (max-width: $xl) {
		width: 100%;
	}

	.crowther-mint-error {
		width: 100% !important;
		margin-bottom: 16px !important;
		background-color: #ffffff !important;
		color: #a32e2c !important;
		padding: 12px !important;

		font-family: interstate, sans-serif !important;
		font-style: normal !important;
		font-weight: 800 !important;
		font-size: 18px !important;
		line-height: 100% !important;
		text-align: center !important;

		margin-top: 0px !important;
	}

	.crowther-mint-size-selector-label {
		color: #ffffff;
		font-family: interstate, sans-serif !important;
		font-style: normal !important;
		font-weight: 800 !important;
		font-size: 16px !important;
	}

	.crowther-mint-size-selector {
		margin-right: 16px;
		width: 160px;
		padding: 8px 16px;

		font-family: interstate, sans-serif !important;
		font-style: normal !important;
		font-weight: 800 !important;
		font-size: 16px !important;
	}

	.crowther-mint-button {
		padding-left: 16px;
		padding-right: 16px;
		height: 100%;
		width: 100%;

		background-color: #cf263b !important;
		color: #ffffff !important;
	}
}

.crowther-mint-button-full {
	display: block !important;
}
