@import "../../custom.scss";
@import "../../layout";

.modal-dialog {
  max-width: 464px !important;
}

.drop2-nft {
  display: flex;
  flex-direction: column;

  // big
  @media (min-width: $lg) {
    padding: 32px;
  }

  // small
  @media (max-width: $lg) {
    padding: 16px;
  }

  .drop2-nft-close {
    cursor: pointer;
    align-self: flex-end;
  }

  .drop2-nft-image {
    background-color: #b0b0b0;
    margin-top: 32px;
    width: 100%;
  }

  h1 {
    margin-top: 16px;
  }

  .drop2-nft-attributes {
    display: flex;

    margin-top: 24px;

    // big
    @media (min-width: $lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    // small
    @media (max-width: $lg) {
      flex-direction: column;
    }

    p {
      font-family: interstate, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      // small
      @media (max-width: $lg) {
        margin-top: 8px;
      }
    }
  }

  .drop2-mint-button {
    width: 100%;
    margin-top: 32px;
  }
}
